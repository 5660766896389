"use client";

import React, { useState, useEffect } from "react";

const AnimatedAsciiStar = () => {
  const [asciiArt, setAsciiArt] = useState("");
  const [frame, setFrame] = useState(0);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    fetch("/public/star.html")
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const content = doc.body.textContent || "";
        setAsciiArt(content);
      })
      .catch((error) => console.error("Error fetching star.html:", error));
  }, []);

  useEffect(() => {
    if (isClient) {
      const intervalId = setInterval(() => {
        setFrame((prevFrame) => (prevFrame + 1) % 4);
      }, 250);
      return () => clearInterval(intervalId);
    }
  }, [isClient]);

  const animateWhiteSpace = (char) => {
    if (char === " " && isClient) {
      const animationChars = [" ", ".", "·", "°"];
      return animationChars[frame];
    }
    return char;
  };

  const animatedAsciiArt = asciiArt
    .split("\n")
    .map((line) => line.split("").map(animateWhiteSpace).join(""))
    .join("\n");

  return (
    <div>
      {!isClient && (
        <noscript>
          <p>
            Please enable JavaScript to see the animated version of this ASCII
            art.
          </p>
        </noscript>
      )}
      <pre
        style={{
          fontFamily: "monospace",
          whiteSpace: "pre",
          backgroundColor: "black",
          color: "white",
          fontSize: "8px",
          lineHeight: "8px",
          padding: "20px",
          margin: "0",
          overflow: "auto",
          maxHeight: "80vh",
          maxWidth: "100%",
        }}
      >
        {animatedAsciiArt || "Loading..."}
      </pre>
    </div>
  );
};

export default AnimatedAsciiStar;

import AnimatedAsciiStar from "./components/AnimatedASCII";
import "./Welcome.css";
import React from "react";

const Welcome = () => {
  return (
    <div className="welcome">
      <AnimatedAsciiStar></AnimatedAsciiStar>
      {/* <img src="/blank.png" alt="Logo" className="silhouette" /> */}
    </div>
  );
};

export default Welcome;
